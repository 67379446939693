<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <frp-logo />

        <h2 class="brand-text text-primary ml-1">FA</h2>
      </b-link>
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img :src="imgUrl" fluid alt="Register V2" />
        </div>
      </b-col>
      <b-col lg="6" class="d-flex align-items-center auth-bg p-lg-3">
        <b-col sm="8" md="10" lg="12" class="px-xl-2 mx-auto">
          <b-card-title title-tag="h2" class="font-weight-bold mb-1">
            <span class="text-primary">&#8377</span> Simple Pricing 
          </b-card-title>
          <b-card-text class="mb-2">
            No hidden fees. Only pay what you see below...
          </b-card-text>
          <div>
            <b-row>
              <b-col sm="12" md="6" class="mt-1" v-for="item,index in reldata" :key="'pricing'+index">
                <b-card class="text-center">
                  <b-card-title class="text-primary">
                    {{item.name}}
                  </b-card-title>  
                  <h1 class=" mt-2text-primary">
                    &#8377 {{item.total}} 
                  </h1>  
                  <h3 class="text-center">
                    <span v-if="item.days>0">{{item.days}} Days /</span>
                    <span v-if="item.months>0">{{item.months}} Months /</span>
                    <span v-if="item.year>0">{{item.year}} Year /</span>
                  </h3> 
                  <hr>
                  {{item.details}}
                <b-button
                v-if="userData"
                  block
                  type="button"
                  class="mt-2"
                  @click="checkpricing(item)"
                  variant="outline-primary"
                >
                  {{item.total>0?'Buy Now':'Start Free Trial'}}
                </b-button>
                <b-button
                v-else
                  block
                  type="button"
                  class="mt-2"
                  variant="outline-secondary"
                >
                Please Login to Proceed
                </b-button>
                </b-card>
              </b-col></b-row
            >
          </div>
        </b-col>
      </b-col>
      <!-- /Reset password-->
    </b-row>
  </div>
</template>


<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import inputtext from "@/views/Component/Input/inputtext.vue";
import axios from "@axios";
import FrpLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BCardText,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BFormInput,
  BButton,
  BImg,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import { required } from "@validations";
import router from "@/router";

import { initialAbility } from "@/libs/acl/config";
import { useToast } from "vue-toastification/composition";
import store from "@/store/index";
import StoreModule from "./StoreModule";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from "@/auth/utils";
import formValidation from "@core/comp-functions/forms/form-validation";

import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    FrpLogo,
    BRow,
    BCol,
    BButton,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    inputtext,
    BFormGroup,
    BImg,
    BInputGroup,
    BLink,
    BFormInput,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormInput,
    BButton,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      userEmail: "",
      cPassword: "",
      password: "",
      sideImg: require("@/assets/images/pages/reset-password-v2.svg"),
      required,
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  setup(props, { emit }) {
    const GENAPP_APP_STORE_MODULE_NAME = "auth-plans";
    const modulename = "Plans";
    const modulenamesub = "Plans";

    if (!store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
      store.registerModule(GENAPP_APP_STORE_MODULE_NAME, StoreModule);
    const toast = useToast();
    onUnmounted(() => {
      if (store.hasModule(GENAPP_APP_STORE_MODULE_NAME))
        store.unregisterModule(GENAPP_APP_STORE_MODULE_NAME);
    });
    const reldata = ref([]);
    store.dispatch(GENAPP_APP_STORE_MODULE_NAME + "/plans").then((response) => {
      reldata.value = response.data["data"];
    });
    let key = 0;
    let edittype = 0;
    let storetype = "addData";
    let iddata = router.currentRoute.params.id;
    const blankAppData = {
      otp: "",
    };
    const appData = ref(JSON.parse(JSON.stringify(blankAppData)));
     
    const userData = getUserData()
    if (iddata != undefined) {
      edittype = 1;
      storetype = "editData";
    }

    const checkpricing = (item) => {
      axios
        .post("v1/pricingverify", {
          planid: item.id,
        })
        .then((response) => {
          if (response.data.xdata.paysno) {
            router
              .push({
                name: "plan-payment",
                params: {
                  data: response.data.data,
                },
              })
              .catch(() => {});
          } else {
            router
              .push({
                name: "plan-paymentverify",
                params: {
                  data: response.data.data,
                },
              })
              .catch(() => {});
          }
          // toast({
          //   component: ToastificationContent,
          //   props: {
          //     title: "Success",
          //     text:
          //       modulename +
          //       (edittype == 1 ? " editted" : " added") +
          //       " successfully",
          //     icon: "CheckIcon",
          //     variant: "success",
          //   },
          // });
        })
        .catch((error) => {
          if (error.response.status === 404) {
            appData.value.otp = null;
            toast({
              component: ToastificationContent,
              props: {
                title: error.response.data.title,
                text: error.response.data.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        });
    };
    const resetappData = () => {
      appData.value = JSON.parse(JSON.stringify(blankAppData));
    };
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetappData);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());

    return {
      GENAPP_APP_STORE_MODULE_NAME,
      edittype,
      iddata,
      appData,
      modulename,
      modulenamesub,

      refFormObserver,userData,
      getValidationState,
      resetForm,
      key,
      today,
      reldata,
      checkpricing,
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/reset-password-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
      localStorage.removeItem("userData");
      this.$ability.update(initialAbility);
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
